.menu-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 1000;
}

.menu-nav a {
  color: white;
  text-decoration: none;
  margin-right: 1rem;
}

.menu-nav a:last-child {
  margin-right: 0;
}

.menu-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.menu-nav a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.menu-nav a:hover,
.menu-nav a.active {
  color: #61dafb;
  border-bottom: 2px solid #61dafb;
}
