.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80rem;
  /* margin: 2rem auto; */
  max-width: 960px;
}

.project-summary {
  background-color: #f7f7f7;
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  color: #333;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 20rem;
  margin: 1rem;
  overflow: hidden;
  position: relative;
  width: 40rem;
}

.project-summary:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  z-index: 1;
}

.project-summary:hover .gradient {
  transform: translateY(0);
}

.project-summary h1 {
  display: flex;
  justify-content: center;
  height: 80px;
  flex-direction: column;
  text-align: center;
  color: #333;
  font-size: 1.53rem;
  font-weight: 900;
  margin-bottom: 0;
  z-index: 2;
}

.project-summary .gradient {
  background: linear-gradient(rgba(22, 20, 20, 0), rgba(255, 255, 255, 0.8));
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  left: 0;
  /* padding: 1rem 1rem 1rem 1rem; */
  position: absolute;
  transform: translateY(90%);
  transition: transform 0.3s ease;
  width: 100%;
  z-index: 2;
  /* padding-bottom: 1rem; */
}

.project-summary p {
  font-size: 0.7rem;
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.project-summary .techs {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.project-summary .tech {
  background-color: #333;
  color: #fff;
  font-size: 0.7rem;
  margin: 0.2rem;
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
}

.project-summary .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
  .buttons img{
    width: auto;
    height: 5vh;
    margin: 1rem;
  }

@media (max-width: 600px) {
  .project-summary {
    height: 15rem;
    width: 15rem;
  }

  .project-summary h3 {
    font-size: 1.25rem;
  }
}
