/* default styles for all devices */
html,
body {
  height: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  margin-bottom: 2rem;
  border: 5px solid #000000;
  filter: grayscale(0%);
  transition: filter 0.2s ease-in-out;
}

.profile-img:hover {
  background-color: aqua;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 2em;
}

.info h1 {
  font-size: 3rem;
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid black;
  text-align: right;
  padding-right: 1rem;
}

h1 {
  display: flex;
  justify-content: flex-end;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif;
  margin-top: 0px;
}

h3 {
  display: flex;
  justify-content: flex-end;
  height: 30px;
}

.typing {
  display: flex;
  align-items: flex-end;
  height: 30px;
  animation: typing 0.8s steps(15, end) infinite, blink-caret 0.75s step-end infinite;
}

.replace {
  display: flex;
  align-items: flex-end;
  height: 30px;
  animation: replace 0.8s steps(15, end) infinite, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 100% }
  to { width: 100% }
}

@keyframes replace {
  from { width: 100% }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(176, 120, 6) }
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav img {
  width: 50px;
  height: 50px;
  margin: 0 1rem;
}

.nav-img:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  background-color: aqua;
  border-radius: 25px;
}

.nav-item:hover img {
  filter: brightness(50%);
}

/* media query for smaller devices in portrait orientation */
@media only screen and (orientation: portrait) and (max-width: 768px) {

  /* adjust layout */
  .profile-container {
    flex-direction: column;
    align-items: center;
  }

  .info {
    margin: 0;
    text-align: center;
    margin-top: 1.5rem;
  }

  .info h1 {
    font-size: 2rem;
    text-align: center;
    padding-right: 0;
    border: none;
    margin: 0;
  }
}
